<template>
    <!-- 网站关停状态 -->
    <div class="main">网站关停状态</div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.main {
    height: 600px;
    line-height: 600px;
}
</style>
